@font-face {
  font-family: 'MeninBlue';
  src: url('../../fonts/MIB.ttf');
}
/* body{background-color:#fff} */
#auth {
  height: 100vh;
  overflow-x: hidden;
}
#auth #auth-right {
  background: url(../../../assets/images/4853433.png?45649b87e0b3f50bfa1372c6cdb4595f),
    linear-gradient(90deg, #2d499d, #3f5491);
  height: 100%;
}
#auth #auth-left {
  padding: 5rem 8rem;
}
#auth #auth-left .auth-title {
  font-size: 4rem;
  margin-bottom: 1rem;
}
#auth #auth-left .auth-subtitle {
  color: #a8aebb;
  font-size: 1.7rem;
  line-height: 2.5rem;
}
#auth #auth-left .auth-logo {
  margin-bottom: 7rem;
}
#auth #auth-left .auth-logo img {
  height: 2rem;
}
@media screen and (max-width: 767px) {
  #auth #auth-left {
    padding: 5rem;
  }
}
@media screen and (max-width: 576px) {
  #auth #auth-left {
    padding: 5rem 3rem;
  }
}
body.theme-dark #auth-right {
  background: url(../../../assets/images/4853433.png?45649b87e0b3f50bfa1372c6cdb4595f),
    linear-gradient(90deg, #2d499d, #3f5491);
}

/* Logo  */
.contenidoError {
  /* border: #000000 solid 1px; */
  position: relative;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout {
  display: inline-block;
  position: relative;
  margin-top: 0%;
  width: 50%;
}

.eye {
  width: 150px;
  margin: 10px;
  display: inline-block;
  float: left;
}
.eye--eyebrow {
  position: relative;
  top: -10px;
  left: 8px;
  float: left;
  width: 120px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0px -15px 0px 0px #ff3300;
}
.eye--ball,
.eye--ball2 {
  position: absolute;
  top: 20;
  left: 25px;
  background-color: white;
  transform: rotate(-135deg);
  width: 110px;
  height: 110px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.eye--ball2 {
  transform: rotate(45deg);
}
.eye--cornia {
  position: relative;
  top: 10px;
  left: 8px;
  width: 90px;
  height: 90px;
  background: #996633;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
.eye--pupil {
  position: inherit;
  top: 25%;
  left: 25%;
  width: 40px;
  height: 40px;
  background: #000000;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
}

.bee {
  display: inline-block;
  width: 150px;
  height: 100px;
  margin-right: 30px;
}
.bee--body {
  position: relative;
  left: 50px;
  width: 50px;
  height: 90px;
  background: #ffcc00;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
  background: repeating-linear-gradient(
    to bottom,
    #ffcc00,
    #ffcc00 15px,
    transparent 15px,
    transparent 25px
  );
}
.bee--eye {
  position: relative;
  left: 48px;
  width: 25px;
  height: 25px;
  background: #ffcccc;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
}
.bee--eye-left {
  display: inline-block;
}
.bee--eye-right {
  display: inline-block;
  margin-left: 5px;
}
.bee--wing-right,
.bee--wing-left {
  display: inline-block;
  position: relative;
  top: -80px;
  left: 25px;
  border-radius: 0px 0px 50% 50%;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 75px solid #00cc00;
  border-top-right-radius: 0%;
  transform-origin: center 0%;
  transform: rotate(40deg);
}
.bee--wing-right {
  transform: rotate(-40deg);
}

.M {
  display: inline-block;
  position: relative;
  vertical-align: text-top;
  line-height: 150px;
  margin-top: -190px;
  font-size: 900%;
  font-family: 'MeninBlue';
  color: dodgerblue;
}
