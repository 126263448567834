.despedido-badge {
  position: absolute;
  top: 15%;
  left: 60%;
  margin-right: -50px;
  transform: translate(-50%, -50%) rotate(-10deg);
  font-size: 1.5rem;
  color: red;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 1;
  border: 3px solid red;
  padding: 10px 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
