@font-face {
  font-family: 'MeninBlue';
  src: url('../../fonts/MIB.ttf');
}

.layout-error {
  display: flex;

  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 15px;
  gap: 5px;
}

.eye-error {
  width: 150px;
  margin: 10px;
  display: inline-block;
  position: relative;
}

.eye-error-eyebrow {
  position: relative;
  top: -10px;
  left: 8px;
  float: left;
  width: 120px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0px -15px 0px 0px #ff3300;
}

.eye-error-ball .eye-error-ball2 {
  position: relative;
  top: 20;
  left: 25px;
  background-color: white;
  transform: rotate(-135deg);
  width: 110px;
  height: 110px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.eye-error-cornia {
  position: relative;
  top: 10px;
  left: 24px;
  width: 90px;
  height: 90px;
  background: #996633;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.eye-error-pupil {
  position: inherit;
  top: 25%;
  left: 25%;
  width: 40px;
  height: 40px;
  background: #000000;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
}

.bee-error {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 100px;
  margin-right: 30px;
}

.bee-error-body {
  position: relative;
  left: 50px;
  width: 50px;
  height: 90px;
  background: #ffcc00;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
  background: repeating-linear-gradient(
    to bottom,
    #ffcc00,
    #ffcc00 10px,
    transparent 10px,
    transparent 20px
  );
}

.bee-error-eye {
  position: relative;
  left: 48px;
  width: 25px;
  height: 25px;
  background: #ffcccc;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
}

.bee-error-eye-left {
  display: inline-block;
}
.bee-error-eye-right {
  display: inline-block;
}

.bee-error-wing-right,
.bee-error-wing-left {
  display: inline-block;
  position: relative;
  top: -80px;
  left: 25px;
  border-radius: 0px 0px 50% 50%;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 75px solid #00cc00;
  border-top-right-radius: 0%;
  transform-origin: center 0%;
  transform: rotate(40deg);
}

.bee-error-wing-right {
  position: relative;

  transform: rotate(-40deg);
}

.M-error {
  font-size: 900%;
  font-family: 'MeninBlue';
  color: dodgerblue;
}

/* posisionate .textError in the end of the page
   .textoError {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
 
  } 

  .textoError h1 {

    position: absolute;
    font-size: 5rem;
    margin-bottom: 4rem;
    font-weight: bold;
    text-align: center;

  }

  .textoError h2 {
    position: relative;
    font-size: 3rem;
    text-align: center;

  }

  


  .layout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  
  .eye {
    display: flex;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 40px;
    margin-top: 80px;
    justify-content: center;
    align-items: center;
  }
  .eye--eyebrow {
    position: relative;
    top: -30px;
    left: 2px;
    float: left;
    width: 120px;
    height: 90px;
    border-radius: 50%;
    box-shadow: 0px -15px 0px 0px #ff3300;
  }
  .eye--ball, .eye--ball2 {
    position: absolute;
    top: 20;
    left: 25px;
    background-color: white;
    transform: rotate(-135deg);
    width: 110px;
    height: 110px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .eye--ball2 {
    transform: rotate(45deg);
  }
  .eye--cornia {
    position: relative;
    top: 10px;
    left: 8px;
    width: 90px;
    height: 90px;
    background: #996633;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
  }
  .eye--pupil {
    position: inherit;
    top: 25%;
    left: 25%;
    width: 40px;
    height: 40px;
    background: #000000;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
  }
  
  .bee {
    display: inline-block;
    width: 150px;
    height: 100px;
    margin-right: 30px;
  }
  .bee--body {
    position: relative;
    left: 50px;
    width: 50px;
    height: 90px;
    background: #ffcc00;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    background: repeating-linear-gradient(to bottom, #ffcc00, #ffcc00 15px, transparent 15px, transparent 25px);
  }
  .bee--eye {
    position: relative;
    left: 48px;
    width: 25px;
    height: 25px;
    background: #ffcccc;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
  }
  .bee--eye-left {
    display: inline-block;
  }
  .bee--eye-right {
    display: inline-block;
    margin-left: 5px;
  }
  .bee--wing-right, .bee--wing-left {
    display: inline-block;
    position: relative;
    top: -80px;
    left: 25px;
    border-radius: 0px 0px 50% 50%;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 75px solid #00cc00;
    border-top-right-radius: 0%;
    transform-origin: center 0%;
    transform: rotate(40deg);
  }
  .bee--wing-right {
    transform: rotate(-40deg);
  }
  
  .M {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-size: 900%;
    font-family: "MeninBlue";
    color: dodgerblue;
  } */
